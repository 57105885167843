.App {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.text {
  color: white;
  &__heading {
    font-size: 36px;
    margin-bottom: 25px;
    padding: 0px 20px;
    @media (max-width: 767px) {
      font-size: 26px;
    }
  }
  &__quiet {
    font-size: 26px;
    padding: 0px 20px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}
.fp-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
}
.image {
  margin-top: 40px;
  img {
    width: 75%;
    margin: auto;
    max-width: 400px;
    transition: .5s;
    &:hover {
      width: 80%;
      max-width: 410px;
      transition: .3s;
    }
  }
}

#fp-nav ul li a span {
  background: white !important;
}

.camera_tag {
  max-width: 100%;
  background: #3883d8;
  box-shadow: none;
  border: none;
  border-radius: 7px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.camera_tag .cameratag_screen {
  background: none;
}
.cameratag_screen.cameratag_playback, .cameratag_screen.cameratag_recording, .cameratag_screen.cameratag_count, .cameratag_screen.cameratag_accept {
  background: none !important;
}

.cameratag_primary_link {
  color: white;
  margin: auto;
  display: flex;
  width: 100px;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}
.cameratag_primary_link_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  margin: auto;
}

.cameratag_select_prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  margin: auto;
}

.cameratag_prompt_label {
  bottom: -40px;
}
